<template>
<div>
    <div class="title_div" @click= declare_api()>供应商应付报表</div>
    <div v-if="pagetype==1"  class="every_option">
      <div style="display:flex;">
        <div style="font-size:3.4vw;margin-left:6vw;">开始时间</div>
      </div>
      <div style="width:30vw;height:100%;display:flex;align-items:center;" @click="chooseDate(1)">
        <div style="font-size:3.4vw;margin-left:7vw;" >{{query.Period? query.Year+'-'+query.Period:'点击选择'}}</div>
      </div>
    </div>
    <div v-if="pagetype==2" class="every_option">
      <div style="display:flex;">
        <div style="font-size:3.4vw;margin-left:6vw;">名称</div>
      </div>
       <div style="display:flex;align-items:center;margin-right:9vw;">
          <input type="text" v-model="query2.name"  placeholder="请输入" style="text-align:right;width:30vw;font-size:3.4vw;">
      </div>
    </div>
    <!-- <div  class="every_option">
      <div style="display:flex;">
        <div style="font-size:3.4vw;margin-left:6vw;">结束时间</div>
      </div>
      <div style="width:30vw;height:100%;display:flex;align-items:center;" @click="chooseDate(2)">
        <div style="font-size:3.4vw;margin-left:7vw;" >{{query.endPeriod?query.endYear +'-'+query.endPeriod:'点击选择'}}</div>
      </div>
    </div> -->
    <!--查询与返回-->
    <div style="display:flex;justify-content:center;margin:2vh 0;">
      <van-button type="info"  size="small" @click="queryBtn()">确定查询</van-button>
      <div style="width:6vw;"></div>
      <van-button type="default"  size="small" @click="goBack()">返回上级</van-button>
    </div>
    <!--查询主体-->
    <div style="overflow:scroll;">
      <div style="padding-left:2vw;overflow:scroll">
        <div style="display:flex;padding-bottom:1vh;">
          <div style="min-width:8vw;"></div>
          <div v-for="(item,index) in table_data[0]" :key="index" :style="(index=='名称'||index=='供应商名称')?'min-width:60vw;':''" class="every_table_title">
            <div>{{index}}</div>
          </div>
        </div>
        <div v-for="(item,index) in table_data" :key="index" class="every_select" >
          <div v-if="index+1<table_data.length" style="min-width:8vw;height:8vh;line-height:8vh;text-align:center;border:1px solid #E2E2E2;">{{index+1}}</div>
          <div v-else style="min-width:8vw;height:8vh;line-height:8vh;text-align:center;border:1px solid #E2E2E2;">总计</div>
          <div v-for="(item2,index2) in item"  :key="index2" style="display:flex;">
            <div class="every_table_option" :style="(index2=='名称'||index2=='供应商名称')?'min-width:60vw;':''">
              <div style="margin:0 5px;">{{item2}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--底部弹出-->
    <van-popup v-model="choose_popup" position="bottom" >
        <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月"  :max-date="maxDate" @confirm="chooseDateBtn" @cancel="cancelBtn"/>
    </van-popup>
</div>
</template>

<script>
export default {
  data() {
      return {
        pagetype:0,
        choose_popup:false,
        maxDate: 0,//最大时间不超过今天
        choose_type:0,//弹窗状态 1开始时间 2结束时间
        currentDate: 0,
        query:{
            Year:'',
            Period:'',
        },
        query2:{
          name:'',
        },
        table_data:[],//数据列表
        loading:'',//loading
      };
  },
  created(){
    this.pagetype = this.$route.query.pagetype*1;
    if(this.pagetype==1){
      this.init();
    }
  },
  methods:{
      init(){
        let new_time = new Date();
        this.maxDate = new_time;
        this.currentDate = new_time;
      },
      chooseDate(e){
        this.choose_type = e;
        this.choose_popup = true;
      },
      //loading
      showLoading(){
         this.loading = this.$loading({
            lock: true,
            text: '查询中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
      },
      //弹窗确定按钮
      chooseDateBtn(){
        this.choose_popup = false;
        let nowDate = new Date(this.currentDate);
        let choose_year = nowDate.getFullYear();
        let choose_month  = nowDate.getMonth() + 1;
        // if(this.choose_type==1){//选中开始时间
        //   this.query.startYear = choose_year;
        //   this.query.startPeriod = choose_month;
        // }else{
        //   this.query.endYear = choose_year;
        //   this.query.endPeriod = choose_month;
        // }
        this.query.Year = choose_year;
        this.query.Period = choose_month;
        this.currentDate = new Date();
      },
      //弹窗取消按钮
      cancelBtn(){
          this.choose_popup = false;
      },
      //查询按钮
      queryBtn(){
        if(this.pagetype==1){
          this.declare_api();
        }else{
          this.amountData_api();
        }
      },
      //以销定结供应商应付报表
      declare_api(){
        this.showLoading();
        this.$axios.post(this.$api.declare_api,{
          userId:JSON.parse(localStorage.getItem("user_info")).userId,
          userType:JSON.parse(localStorage.getItem("user_info")).type,
          ...this.query
          }).then(res=>{
            console.log(res,"sjdhfjkshnfjkd")
            this.tableDataFilter(res.data.data);
            this.loading.close();
        })
      },
      //查询供应商列表
      amountData_api(){
        this.showLoading();
        this.$axios.post(this.$api.amountData_api,{
          userId:JSON.parse(localStorage.getItem("user_info")).userId,
          userType:JSON.parse(localStorage.getItem("user_info")).type,
          name:this.query2.name
          }).then(res=>{
            console.log(res,"1221")
            let new_data = res.data.data;
            for(let i=0;i<new_data.length;i++){
              delete new_data[i].助记码
            }
            //去除供应商应付报表
            this.tableDataFilter(new_data);
            this.loading.close();
          })
      },
      //表数据格式化
      tableDataFilter(data){
          this.table_data = data;
          let total = {}
          for(let i=0;i<this.table_data.length;i++){
            for(let key in this.table_data[i]){
              //若为数字类型，则计算总价
              if(typeof this.table_data[i][key]=='number'){
                if(total[key]){
                  total[key] = total[key] + this.table_data[i][key];
                }else{
                  total[key] = this.table_data[i][key];
                  console.log(total)
                }             
              }else{
                total[key] = '';
              }
            }
          }
          //格式化
          for(let key in total){
            if(typeof total[key]=='number'){
              total[key] = total[key].toFixed(2);
            }
          }
          this.table_data.push(total);
      },
      //返回上级
      goBack(){
        this.$router.go(-1);
      }
  }
}
</script>
<style scoped>
input{
  border: #FFFFFF;
}
.title_div{
  height:9vh;
  background-image: linear-gradient(#04A9F3, #4EC5FB);
  line-height:12vh;
  color:#FFFFFF;
  text-align: center;
  font-size:4.6vw;
}
.every_option{
  height:7vh;
  display:flex;
  justify-content:space-between;
  align-items:center;
  border-bottom: 1px solid #E2E2E2;
}
.every_select{
  display:flex;
  font-size:3.5vw;
  flex-wrap: nowrap;
  align-items:center;
  /* border: 1px solid #E2E2E2; */
}
.every_table_option{
  min-width:30vw;
  border: 1px solid #E2E2E2;
  height:8vh;display:flex;
  justify-content:center;
  align-items:center;
}
.every_table_title{
  min-width:30vw;
  font-size:3.5vw;
  display:flex;
  justify-content:center;
}
</style>